:root {
    --gray: #4B4B4B;
    --midgray: #707070;
    --celeste: #4EC3F2;
    --bgbluel: #234364;
    --darkblue: #15304B;
    --green: #8FC73E;
    --bluepilar: #2D6186;
}

body,
html {
    margin: 0px;
    padding: 0;
    font-family: 'Raleway', sans-serif;
    color: var(--gray);
    scroll-behavior: smooth;
}

@mixin transition {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background-image 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, display 0.15s ease-in-out;
}

* {
    box-sizing: border-box;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

a {
    text-decoration: none;
    font-family: 'Raleway', sans-serif;
}

// my classes
.displaynone {
    display: none;
}

.main-btn,
.send {
    background: var(--celeste);
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    padding: 10px 40px;
    border-radius: 6px;
    width: fit-content;
    &:hover {
        background: var(--green);
        color: var(--bgbluel);
    }
}

.thishidden {
    visibility: hidden;
    transition: visibility 0.15s ease-in-out;
}

h2,
.ttl {
    color: var(--bgbluel);
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    padding: 10px 30px;
    width: fit-content;
    border-bottom: 6px solid var(--celeste);
    margin-bottom: 20px !important;
}

.form h3 {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 10px !important;
    width: 100%;
}

.white {
    color: #ffffff;
}

.m-center {
    margin: 0 auto;
}

p {
    font-size: 19px;
    line-height: 28px;
}

// END my classes
nav {
    background-color: #fff;
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    height: 80px;
    align-items: center;
    .menumobile {
        display: none;
    }
    a {
        font-size: 18px;
        display: inline-flex;
        padding: 15px !important;
        margin-left: 20px;
        text-decoration: none;
        color: var(--midgray);
        border-bottom: 2px solid #fff;
        @include transition();
        &:hover {
            border-color: var(--celeste);
        }
    }
}

.container {
    max-width: 1322px !important;
    margin: 0 auto !important;
}

nav .container {
    padding: 0px;
}

.fixed-top {
    top: -80px;
    transform: translateY(80px);
    transition: transform .3s;
}

.head-container {
    max-width: 100%;
    // background: url(../../images/waves-header.svg) bottom center no-repeat;
    // background-size: contain;
    // height: 100vh;
    // min-height: 770px;
    .container {
        display: flex;
        padding-top: 50px;
        // height: 600px;
        justify-content: space-between;
        align-items: center;
        .c4h-intro {
            width: 580px;
            height: fit-content;
            h1 {
                font-weight: 700;
                font-size: 29px;
            }
            img {
                width: 100%;
                height: auto;
                max-width: 420px;
            }
        }
        .img {
            width: 600px;
            height: fit-content;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

.about-us-container {
    background: var(--bgbluel) url(../../images/waves-header.svg) top center no-repeat;
    //background-size: contain;
    padding-top: 360px;
    padding-bottom: 150px;
    #aboutus {
        padding-top: 110px;
    }
    .container {
        h2 {
            margin-bottom: 50px !important;
        }
        .c4h-about {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 75px;
            .about {
                max-width: 50%;
            }
            img {
                height: fit-content;
            }
        }
        .pilars {
            display: flex;
            justify-content: space-between;
            margin: 150px 0 40px;
            gap: 15px;
            .pilar {
                width: 300px;
                height: 220px;
                background: var(--bluepilar);
                border-radius: 16px;
                padding: 15px;
                text-align: center;
                .icon {
                    border-radius: 16px;
                    background: #fff;
                    padding: 15px;
                    width: 90px;
                    height: 90px;
                    margin: -50px auto 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                .pilar-ttl {
                    font-size: 24px;
                    font-weight: 700;
                    margin: 30px 0 0;
                }
                .pilar-txt {
                    margin: 0;
                    font-size: 16px;
                    text-align: center;
                }
            }
        }
    }
}

.stats-container {
    background: url(../../images/bg-data.jpg) center no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    .wavestats {
        width: 100%;
        height: auto;
    }
    .container {
        display: flex;
        justify-content: space-between;
        .c4h-data {
            width: 250px;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                width: 110px;
                width: 110px;
                margin-bottom: 10px;
            }
            p {
                font-size: 30px;
                text-transform: uppercase;
                font-weight: 700;
                color: #fff !important;
                margin: 0px auto;
                text-align: center;
                line-height: 1.2em;
            }
        }
    }
}

.newsletter {
    margin: 150px auto;
    .newsubscribe {
        display: flex;
        height: 200px;
        width: 100%;
        justify-content: center;
        align-items: center;
        background: #f6f6f6;
        p {
            font-size: 30px;
            color: var(--celeste);
            text-transform: uppercase;
            font-weight: 700;
            width: 280px;
            margin: 0px;
            margin-right: 20px;
        }
        form {
            width: 60%;
            display: flex;
            gap: 10px;
            .newslettermail {
                width: 70%;
                height: 60px;
                border-radius: 8px;
                border: 1px solid #E3E3E3;
                padding: 10px;
                font-size: 20px;
            }
            .btnsubscribe {
                width: 30%;
                border-radius: 8px;
                border: 0;
                background-color: var(--celeste);
                font-size: 20PX;
                font-weight: 700;
                &:hover {
                    background: var(--green);
                    color: #fff;
                }
            }
        }
    }
}

.gallery-container {
    padding-top: 150px;
    .container {
        .photo-gallery {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin: 80px auto 150px;
            .item {
                padding: 0px;
                display: flex;
                margin-block-start: 0;
                margin-block-end: 0;
                margin-inline-start: 0;
                margin-inline-end: 0;
                width: 25%;
                a {
                    display: flex;
                    background: var(--darkblue);
                    img {
                        width: 100%;
                        height: auto;
                        transition: opacity 0.15s ease-in-out;
                        opacity: 1;
                        &:hover {
                            opacity: 0.4;
                        }
                    }
                }
            }
        }
    }
}

.lb-container {
    padding: 0px !important;
}

.lb-nav a.lb-next,
.lb-nav a.lb-prev {
    opacity: 1 !important;
}

.footerwaves {
    width: 100%;
    height: auto;
}

.footer {
    background: var(--bgbluel);
    .container {
        display: flex;
        justify-content: space-between;
        padding: 120px 0;
        gap: 50px;
        .logoaddress {
            display: flex;
            flex-direction: column;
            width: 60%;
            img {
                max-width: 400px;
                margin-bottom: 20px;
            }
            .address {
                display: flex;
                flex-wrap: wrap;
                max-width: 430px;
                gap: 10px;
                p {
                    font-size: 18px;
                    margin: 0px;
                }
                a {
                    font-size: 18px;
                    display: block;
                    width: 100%;
                    color: var(--celeste);
                    &:hover {
                        color: var(--green);
                    }
                }
                .redes {
                    padding: 0px 0 20px;
                    display: flex;
                    justify-content: space-between;
                    width: 150px;

					a{
                        display: block;
                        width: 30px;
                        height: 30px;
						font-size: 25px;

						&:hover {
                            color: var(--green);
                        }
					}
                }
            }
        }
        .form {
            width: 40%;
            max-width: 510px;
            form {
                justify-content: space-between;
                flex-wrap: wrap;
                display: flex;
                input {
                    border-radius: 6px;
                    border: 1px solid #fff;
                    height: 42px;
                    text-indent: 10px;
                    background-color: var(--bgbluel);
                    color: #fff;
                    margin-bottom: 15px;
                    &::placeholder {
                        color: #fff;
                        opacity: 0.8;
                    }
                }
                .name {
                    width: 100%;
                }
                .email {
                    width: 100%;
                }
                .msj {
                    width: 100%;
                    height: 124px;
                    resize: none;
                    border-radius: 6px;
                    border: 1px solid #fff;
                    background-color: var(--bgbluel);
                    color: #fff;
                    padding: 10px 0px;
                    text-indent: 10px;
                    font-family: 'Raleway', sans-serif;
                    margin-bottom: 15px;
                    &::placeholder {
                        color: #fff;
                        opacity: 0.8;
                    }
                }
                .main-btn {
                    background: var(--celeste);
                    color: var(--bgbluel);
                    font-size: 16px;
                    font-weight: 700;
                    display: flex;
                    text-indent: 0px;
                    padding: 10px 30px;
                    border-radius: 6px;
                    width: 100%;
                    justify-content: center;
                    border: 0;
                    &:hover {
                        background: var(--green);
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .designedby {
        background: var(--darkblue);
        color: #fff;
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media(max-width:1400px) {
    .container {
        max-width: 100%;
        width: 100%;
        padding-left: 30px !important;
        padding-right: 30px !important;
        margin: 0 auto;
    }
}

@media(max-width:1200px) {
    .head-container {
        .container {
            padding-bottom: 30px;
            .c4h-intro {
                img {
                    max-width: 300px;
                }
                h1 {
                    margin: 0;
                }
            }
            .img {
                display: flex;
                justify-content: end;
                img {
                    width: 80%;
                    display: flex;
                    align-self: flex-end;
                }
            }
        }
    }
}

@media(min-width:993px) {
    #mobilemenu {
        display: none !important;
    }
}

@media(max-width:992px) {
    .deskmenu {
        display: none;
    }
    .fixed-top {
        top: -70px;
        transform: translateY(70px);
        transition: transform 0.3s;
    }
    .navbar {
        height: auto;
        min-height: 50px;
        padding: 0px;
        .menumobile {
            margin: 10px 0px;
            width: 50px;
            height: 50px;
            display: flex;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            background: #fff;
            border: 0;
            &:hover {
                background-color: var(--bgbluel);
                path {
                    fill: #fff;
                }
            }
        }
        .navbar-collapse {
            padding: 0px;
            .navbar-nav {
                padding: 40px 0px;
                .nav-item {
                    height: 50px;
                    margin-bottom: 15px;
                    .nav-link {
                        padding: 0 20px;
                        margin-left: 0px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        border-radius: 10px;
                        &:hover {
                            background: var(--bgbluel);
                            border: 0;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    .main-btn,
    .send {
        height: 42px;
        font-size: 18px;
    }
    .about-us-container {
        padding-top: 375px;
        .container {
            .c4h-about {
                flex-direction: column-reverse;
                .about {
                    max-width: 100%;
                }
            }
            .pilars {
                flex-wrap: wrap;
                .pilar {
                    width: calc(50% - 8px);
                }
            }
        }
    }
    .newsletter {
        .newsubscribe {
            flex-direction: column;
            p {
                text-align: center;
                margin-bottom: 15px;
                width: 90%;
            }
            form {
                width: 90%;
            }
        }
    }
    .footer {
        .container {
            .logoaddress {
                width: 40%;
            }
            .form {
                width: 60%;
            }
        }
    }
}

@media(max-width:768px) {
    .container {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    nav {
        height: 60px;
        .logo {
            visibility: initial;
            img {
                height: 40px;
            }
        }
        .menu {
            display: none;
        }
    }
    .head-container {
        max-width: 100%;
        .container {
            flex-direction: column-reverse;
            .c4h-intro {
                width: 100%;
                height: fit-content;
                img {
                    display: none;
                }
            }
            .img {
                width: 100%;
                height: fit-content;
                margin-bottom: 60px;
                img {
                    max-width: 400px;
                    margin: 0 auto;
                }
            }
        }
    }
    .about-us-container {
        padding-top: 200px;
        background-size: 100%;
        .container {
            padding: 0px 20px !important;
            .c4h-about {
                img {
                    width: 100%;
                    height: auto;
                    max-width: 534px;
                    margin-bottom: 30px;
                }
            }
        }
    }
    .stats-container {
        .container {
            flex-wrap: wrap;
            gap: 70px;
            width: 650px;
            .c4h-data {
                img {
                    width: 110px;
                    width: 110px;
                    margin-bottom: 10px;
                }
                p {
                    font-size: 30px;
                    text-transform: uppercase;
                    font-weight: 700;
                    color: #fff !important;
                    margin: 0px auto;
                    text-align: center;
                    line-height: 1.2em;
                }
            }
        }
    }
    .gallery-container {
        .container {
            padding: 0px !important;
        }
    }
    .newsletter {
        .container {
            padding: 0px !important;
        }
        .newsubscribe {
            //flex-direction: column;
            height: auto;
            padding: 20px;
            p {
                // text-align: center;
                // margin-bottom: 15px;
                // width: 90%;
            }
            form {
                width: 100%;
                flex-direction: column;
                .newslettermail {
                    width: 100%;
                }
                .btnsubscribe {
                    width: 100%;
                    height: 60px;
                }
            }
        }
    }
    .footer {
        .container {
            flex-direction: column;
            .logoaddress {
                width: 100%;
                max-width: 450px;
                margin: 0 auto;
                align-items: center;
                img {
                    max-width: 300px;
                }
                .address {
                    justify-content: center;
                    a {
                        text-align: center;
                        padding: 10 px;
                    }
                    .redes {
                        width: auto;
                        max-width: 300px;
                        .fb {
                            width: 50px;
                            height: 50px;
                            -webkit-mask-size: 20px;
                            mask-size: 20px;
                        }
                        .insta {
                            width: 50px;
                            height: 50px;
                            -webkit-mask-size: 35px;
                            mask-size: 35px;
                        }
                        .tw {
                            width: 50px;
                            height: 50px;
                            -webkit-mask-size: 36px;
                            mask-size: 36px;
                        }
                    }
                }
            }
            .form {
                width: 100%;
                max-width: 450px;
                margin: 0 auto;
            }
        }
    }
}

@media(max-width:650px) {
    p {
        font-size: 20px;
        line-height: 34px;
    }
    .head-container {
        .container {
            .c4h-intro {
                h1 {
                    font-size: 26px;
                    text-align: center;
                }
                p {
                    text-align: center;
                }
                .main-btn {
                    margin: 0 auto;
                }
            }
        }
    }
    .about-us-container {
        .container {
            .pilars {
                .pilar {
                    min-height: 220px;
                    height: auto;
                    margin-bottom: 50px;
                    .pilar-txt {
                        font-size: 20px;
                        line-height: 34px;
                    }
                }
            }
        }
    }
    .pilars {
        margin-top: 70px !important;
        .pilar {
            width: 100% !important;
        }
    }
    .stats-container {
        .container {
            gap: 50px;
            width: 100%;
            flex-direction: column;
            .c4h-data {
                margin: 0 auto;
            }
        }
    }
}

@media(max-width:600px) {
    .about-us-container {
        padding-top: 375px;
        .container {
            .pilars {
                flex-wrap: wrap;
                .pilar {
                    width: 100%;
                }
            }
        }
    }
    .stats-container {
        .container {
            gap: 20px;
            width: 100%;
            max-width: 470px;
            .c4h-data {
                width: 200px;
                img {
                    width: 80px;
                    width: 80px;
                }
                p {
                    font-size: 24px;
                    text-transform: uppercase;
                    font-weight: 700;
                    color: #fff !important;
                    margin: 0px auto;
                    text-align: center;
                    line-height: 1.2em;
                }
            }
        }
    }
    .gallery-container {
        .container {
            .photo-gallery {
                .item {
                    width: 50%;
                }
            }
        }
    }
}

.mt-5 {
    margin-top: 5rem;
}

.w-img-footer{
    width: 70%;
}